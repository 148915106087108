<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Curso:" v-model="registro.curso" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="* Disciplina:" :items="selectDisc" v-model="registro.id_disciplina" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Ativo:" :items="[{value : 't', text : 'Sim'}, {value : 'f', text : 'Não'}]" v-model="registro.status" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'

export default {
    name: "CursoForm",
    props : ['id_curso'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,
            selectDisc : []
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}curso/get`, {id_curso : this.id_curso}).then( (res) => {
                this.registro = res.data
            })
        },
        salvar() {
            if(!this.id_curso) {
                axios.post(`${this.baseUrl}curso/insert`, {...this.registro }).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/curso/gerenciar/' + res.data.id_curso})
                    }
                })
            } else {
                axios.post(`${this.baseUrl}curso/update`, {...this.registro}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/curso/gerenciar/' + this.id_curso})
                    }
                })
            }
        },
        selectDiscListar() {
            axios.post(`${this.baseUrl}disciplina/list`, {}).then((res) => {
                this.selectDisc = res.data.lista.map((e) => {return {
                    value : e.id_disciplina,
                    text : e.disciplina
                }})
            })
        },
        async init() {
            this.registro = { status : 't' }
            await this.selectDiscListar()
            if(this.id_curso) {
                await this.get()
            }
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
